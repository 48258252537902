import ajax from "@/utils/ajax.js";

/**
 * 获取网点退费列表
 * @returns 
 */
export function getShopRefundList(params){
  return ajax.post('/OutletsPostSalesRefund/getList', params)
}

/**
 * 获取网点退费详情
 * @returns 
 */
export function getShopRefundDetail(params){
  return ajax.post('/OutletsPostSalesRefund/detail', params)
}

/**
 * 审核网点退费
 * @returns 
 */
export function auditShopRefund(params){
  return ajax.post('/OutletsPostSalesRefund/audit', params)
}


/**
 * 修改网点退费
 * @returns 
 */
export function updateShopRefund(params){
  // test 没有这个接口
  return ajax.post('/OutletsPostSalesRefund/update', params)
}

